import { CSS } from "@stitches/react"
import { globalCss, theme } from "@theme/stitches.config"
import { normalize } from "stitches-normalize-css"
import "@fontsource/bebas-neue"
import "@fontsource/space-grotesk/300.css"
import "@fontsource/space-grotesk/400.css"
import "@fontsource/space-grotesk/500.css"
import "@fontsource/space-grotesk/600.css"
import "@fontsource/space-grotesk/700.css"
import "swiper/css/bundle"

const customGlobalStyles: Record<string, CSS> = {
    "*, *::before, *::after": {
        boxSizing: "border-box"
    },
    html: {
        textSizeAdjust: "100%",
        fontFamily: theme.fonts.body
    },
    body: {
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.regular,
        fontSize: "1rem",
        lineHeight: 1,
        margin: 0
    },
    "h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5": {
        wordBreak: "break-word",
        color: theme.colors.N1000,
        fontFamily: theme.fonts.heading,
        margin: 0,
        marginBottom: 20,
        mx: 0
    },
    "h1,h2,h3,.h1,.h2,.h3": {
        fontWeight: theme.fontWeights.regular
    },
    "h4,h5,.h4,.h5": {
        fontWeight: theme.fontWeights.regular
    },
    " p": {
        margin: 0,
        padding: 0,
        fontWeight: theme.fontWeights.semiBold
    },
    ".gatsby-focus-wrapper": {
        overflow: "hidden"
    },
    ".btn": {
        appearance: "none",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        height: 48,
        alignItems: "center",
        border: "none",
        borderRadius: 2,
        textTransform: "uppercase",
        padding: "18px 21px",
        fontSizePxToRem: 21,
        width: "fit-content",
        textDecoration: "none",
        fontFamily: theme.fonts.heading,
        letterSpacing: 0.5,
        lineHeight: 1,
        transition: "all 200ms ease",
        outline: "none",
        mt: 20
    },
    ".btn-primary": {
        backgroundColor: theme.colors.N1000,
        color: theme.colors.N0,
        "&:hover": {
            backgroundColor: theme.colors.ycGreen,
            color: theme.colors.ycBlack
        }
    },
    ".btn-yellow": {
        backgroundColor: theme.colors.ycYellow,
        color: theme.colors.ycBlack,
        "&:hover": {
            backgroundColor: theme.colors.N0,
            color: theme.colors.ycBlack
        }
    },
    ".btn-white": {
        backgroundColor: theme.colors.N0,
        color: theme.colors.ycBlack,
        "&:hover": {
            backgroundColor: theme.colors.N1000,
            color: theme.colors.N0
        }
    },
    ".btn-green": {
        backgroundColor: theme.colors.ycGreen,
        color: theme.colors.ycBlack,
        "&:hover": {
            backgroundColor: theme.colors.N0,
            color: theme.colors.ycBlack
        }
    },
    h1: {
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h1Mobile,
        fontWeight: theme.fontWeights.regular,
        lineHeight: "40px",
        "@mdUp": {
            lineHeight: "54px",
            fontSize: theme.fontSizes.h1
        }
    },
    h2: {
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h2Mobile,
        fontWeight: theme.fontWeights.regular,
        lineHeight: "34px",
        "@mdUp": {
            lineHeight: 54 / 52,
            fontSize: theme.fontSizes.h2
        }
    },
    p: {
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes.mobileBody,
        fontWeight: theme.fontWeights.medium,
        lineHeight: "24px",
        "@mdUp": {
            fontSize: theme.fontSizes.desktopBody
        }
    },
    a: {
        textDecoration: "none",
        cursor: "pointer",
        color: "inherit"
    },
    strong: {
        fontWeight: theme.fontWeights.bold
    }
}

export const globalStyles = globalCss(...normalize, customGlobalStyles)
